.full-text {
  font-size: 1.6rem;
  border-radius: 5px;
  border: solid 1px $seashell;
  box-shadow: 0 0 5px 0 $seashell;
  padding: 40px;

  @include below(sm) {
    padding: 20px;
  }

  a {
    display: inline-block;
    color: $christine;
    margin-bottom: 15px;
  }
}