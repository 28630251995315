.timeline-form {
  p {
    color: $black;
    font-size: 2.6rem;
    line-height: 1.2em;
    font-weight: 700;

    @include below(sm) {
      font-size: 1.6rem;
    }
  }

  input {
    border: none;
    border-bottom: solid 1px $black;
    width: 88px;
    color: $christine;
    font-weight: 700;
    text-align: center;
    padding-left: 10px;
    margin: 0 0 0 10px;
    user-select: text;

    &:focus {
      outline: none;
    }

    @include below(sm) {
      width: 60px;
    }
  }
}
.timeline {
  width: 100%;
  display: flex;
  flex: 0;

  @include below(md) {
    height: 450px;
    flex-direction: column;
  }

  @include below(sm) {
    height: 300px;
  }

  & > div {
    height: 25px;
    position: relative;

    @include below(md) {
      max-height: 100%;
      min-height: 80px;
      width: 15px;

      & > span {
        max-height: 100%;
      }
    }

    @include below(sm) {
      min-height: 55px;
    }

    @include above(md) {
      min-width: 200px;

      & > span {
        min-width: 200px;
      }
    }

    & > .timeline-tooltip {
      position: absolute;
      top: 40px;
      width: 195px;
      height: auto;
      font-size: 2rem;
      border-radius: 4px;
      text-align: left;
      padding: 0;
      box-shadow: 0 0 5px 0 $seashell;

      @include below(md) {
        width: calc(100vw - 65px);
        font-size: 2rem;
        left: 25px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 80px;
      }

      @include below(sm) {
        padding: 10px 0;
        height: 50px;
        font-size: 1.4rem;
      }

      @include below(sm) {
        font-size: 1.2rem;
      }

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 6px 6px 6px;
        border-color: transparent transparent $christine transparent;
        position: absolute;
        top: -6px;
        left: 10px;

        @include below(md) {
          top: 0;
          bottom: 0;
          margin: auto;
          left: -9px;
          transform: rotate(-90deg);
        }
      }

      &.center {
        @include above(md) {
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }

      &.right {
        @include above(md) {
          right: 0;

          &:before {
            left: unset;
            right: 10px;
          }
        }
      }

      p {
        padding: 15px;
        margin: 0;
        font-weight: 600;
        line-height: 1.2em;

        @include below(md) {
          font-size: 2.2rem;
        }

        @include below(sm) {
          font-size: 1.8rem;
          padding: 5px;

          br {
            display: none;
          }
        }

        span.light {
          font-weight: 300;

          @include below(md) {
            font-size: 2rem;
            font-weight: 400;
          }

          @include below(sm) {
            font-weight: 400;
            font-size: 1.2rem;
          }
        }
      }

      span.franchise {
        padding: 5px 15px;
        display: block;
        background-color: transparentize($black, 0.8);
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;

        @include below(md) {
          padding: 5px;
        }
      }
    }

    &.franchise  {
      @include below(md) {
        min-height: 85px;
      }

      @include below(sm) {
        min-height: 65px;
      }

      & > .timeline-tooltip {
        padding-bottom: 30px;

        @include below(md) {
          height: 105px;
        }
        @include below(sm) {
          height: 75px;
        }

      }
    }

    &.rac {
      background-color: $christine;

      &.null {
        background-color: $white;
        border: solid 1px $seashell;

        @include above(md) {
          border-right: none;
        }

        @include below(md) {
          border-bottom: none;
        }
      }

      & > .timeline-tooltip {
        background-color: $christine;
        color: $white;

        p {
          span.light {
            font-weight: 400;
          }
        }
      }
    }

    &.mutuelle {
      background-color: $atomicTangerine;

      & > .timeline-tooltip {
        background-color: $atomicTangerine;

        &:before {
          border-color: transparent transparent $atomicTangerine transparent;
        }
      }
    }

    &.ro {
      background-color: $lightningYellow;

      & > .timeline-tooltip {
        background-color: $lightningYellow;

        &:before {
          border-color: transparent transparent $lightningYellow transparent;
        }
      }
    }
  }
}

.legals {
  font-size: 1.2rem;
  line-height: 1.2em;
  color: $nobel;
  margin-top: 170px;

  @include below(md) {
    margin-top: 50px;
  }
}