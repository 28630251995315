.squareChoices {
  display: flex;
  justify-content: flex-start;
  list-style: none;
  width: 100%;
  position: relative;
  margin: 0 -50px;

  @include below(md) {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 calc(100% / 5);
    margin: 0 10px;
    padding: 20px;
    height: auto;
    border-radius: 5px;
    border: solid 1px $seashell;
    box-shadow: 0 0 5px 0 $seashell;
    font-size: 2rem;
    text-align: center;
    cursor: pointer;
    transition: color 0.4s;

    @include below(md) {
      width: 100%;
      margin: 0 0 20px;
      padding: 40px 20px;

      &:before {
        display: none;
      }
    }

    @include below(sm) {
      font-size: 1.6rem;
      padding: 20px;
    }

    &:hover {
      color: $christine;
    }

    &:before {
      content: '';
      float: left;
      padding-top: 100%;
    }

    &.navigation {
      flex: 0 1 auto;
      min-width: 135px;

      span {
        display: inline-block;
        margin-left: 10px;
        color: $christine;

        &[class^='icon-'] {
          display: flex;
          align-items: center;
          margin-right: 10px;
          margin-left: 0;
        }
      }
    }

    p {
      position: relative;
      margin: 0;
    }
  }
}