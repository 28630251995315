/**
* Used to declare media queries. Usage examples :
* - @include above(sm) {...}
* - @include below(sm) {...}
* - @include between(sm, md) {...}
 */

// @include above(sm) {}
@mixin above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// @include height-above(600px) {}
@mixin height-above($breakpoint) {
  @media (min-height: ($breakpoint - 1)) {
    @content;
  }
}

// @include height-below(600px) {}
@mixin height-below($breakpoint) {
  @media (max-height: ($breakpoint - 1)) {
    @content;
  }
}

// @include height-below(600px) {}
@mixin width-above-height-below($width, $height) {
  @if map-has-key($breakpoints, $width) and map-has-key($breakpoints, $height) {
    $width-breakpoint: map-get($breakpoints, $width);
    $height-breakpoint: map-get($breakpoints, $height);
    @media (min-width: $width-breakpoint) and (max-height: ($height-breakpoint - 1)) {
      @content;
    }
  }
}

// @include below(sm) {}
@mixin below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

// @include height-below(600px) {}
@mixin height-below($breakpoint) {
  @media (max-height: ($breakpoint - 1)) {
    @content;
  }
}

// @include between(sm, md) {}
@mixin between($lower, $upper) {
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }
  } @else {
    @if (map-has-key($breakpoints, $lower) == false) {
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    @if (map-has-key($breakpoints, $upper) == false) {
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin target-old-navigator {
  @media screen and (-ms-high-contrast: none) {
    @content;
  }
}
