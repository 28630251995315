.btn {
  padding: 12px 25px;
  font-size: 1.6rem;
  font-weight: 300;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  outline: none;

  &:hover {
    color: $color-text-light;
  }

  &--fill {
    background-color: $christine;
    color: $color-text-light;
  }

  &--transparent {
    border: solid 1px $christine;
    color: $christine;

    &:focus, &:hover, &:active {
      color: $christine;
    }
  }

  &--icon {
    padding: 10px 30px 10px 10px;
    font-weight: 400;
    display: flex;
    align-items: center;

    &:before {
      margin-right: 30px;
      font-size: 2rem;
    }
  }

  &--upper {
    text-transform: uppercase;
  }

  &--center {
    margin: 0 auto;
    display: table;
  }

  &[aria-disabled="true"] {
    border: solid 1px $seashell;
    box-shadow: 0 0 5px 0 $seashell;
    color: $empress;
  }
}

button.close {
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 100%;
  border: solid 1px;
  color: $christine;
  background-color: $white;
  cursor: pointer;
  outline: none;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 0;
  font-size: 1.8rem;
  font-weight: 700;
}