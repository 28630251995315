html {
  font-size: 10px;
  margin: 0;
  padding: 0;
}

body {
  font-size: 1.4rem;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.71em;
  color: $black;
  font-family: $primary-font;
  overflow-x: hidden;
}

.limited-content {
  max-width: $md-container;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
}

.mobile-only {
  @include above(sm) {
    display: none;
  }
}

.tablet-mobile-only {
  @include above(lg) {
    display: none;
  }
}

.desktop-only {
  @include below(md) {
    display: none;
  }
}

* {
  box-sizing: border-box;
  word-break: break-word;
  user-select: none;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &-content {
    justify-self: center;
    margin-top: auto;
    margin-bottom: auto;
  }
}