.react-tabs {
  margin-bottom: 25px;
}

ul[role="tablist"] {
  display: flex;
  list-style: none;
  margin-top: 10px;
  margin-bottom: 20px;
  border-bottom: solid 1px $seashell;
  padding: 0 0 15px;

  @include below(md) {
    border: none;
    justify-content: center;
  }

  & > li {
    font-size: 2rem;
    font-weight: 700;
    cursor: pointer;
    position: relative;

    @include below(sm) {
      font-size: 1.6rem;
    }

    &[aria-selected=true] {
      &:after {
        content: "";
        position: absolute;
        bottom: -15px;
        left: 0;
        height: 3px;
        width: 100%;
        background-color: $christine;
      }
    }

    &[aria-selected=false] {
      color: $nobel;
    }
  }

  & > li:not(:first-child) {
    margin-left: 25px;
  }
}

div[role="tabpanel"] {
  display: flex;
  justify-content: space-between;

  @include below(md) {
    flex-direction: column;
  }

  & > div {
    flex-grow: 0;
    flex-shrink: 1;
    width: calc(100% / 2 - 10px);
    border-radius: 5px;
    border: solid 1px $seashell;
    box-shadow: 0 0 5px 0 $seashell;
    padding: 25px;

    @include below(md) {
      margin-bottom: 20px;
      width: auto;
    }

    h3 {
      margin-top: 0;
      color: $christine;
      font-weight: 700;
      font-size: 2rem;
    }
  }

  &.simulation {
    & > div {
      flex-grow: 1;
      border: none;
      box-shadow: none;
      padding: 0;
    }
  }
}