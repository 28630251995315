.review-form {
    .review-notes-wrapper {
        display: inline-flex;
        margin-bottom: 20px;

        .review-note {
            &:after {
                content: '★';
                font-size: 3rem;
                color: $nobel;
                transition: color .3s;
            }
        }

        &:hover .review-note:after, .review-note--selected:after {
            color: $gold;
        }

        &:hover .review-note:hover ~ .review-note:after {
            color: $nobel;
        }
    }

    .comment-wrapper {
        width: 100%;

        textarea {
            margin: 0 10% 20px;
            width: 80%;
            height: 100px;
            font-size: 1.8rem;
            font-weight: 300;
            padding: 10px;
        }
    }
}
