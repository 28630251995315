.path_container {
  background-color: transparentize($christine, 0.5);
  height: 4px;
  position: relative;
  margin-bottom: 80px;

  @include below(md) {
    background: none;
    height: auto;
    margin-bottom: 40px;
  }

  .progression {
    height: 4px;
    position: absolute;
    left: 0;
    width: 0;
    background-color: $christine;

    @include below(md) {
      display: none;
    }
  }

  ul {
    display: flex;
    justify-content: center;
    height: 4px;
    padding: 0;

    li {
      display: block;
      margin-top: -39px;
      position: relative;
      min-width: 140px;

      span:not(.prev) {
        display: block;
        text-align: center;
        padding: 0 40px;
        text-transform: uppercase;
        margin-bottom: 10px;
        cursor: pointer;
        z-index: 1;

        &:after {
          content: '';
          display: block;
          margin: 10px auto 0;
          width: 16px;
          height: 16px;
          border-radius: 10px;
          background-color: $christine;
        }
      }
    }

    @include below(md) {
      height: auto;
      justify-content: flex-start;

      & > li:not(:last-child) {
        display: none;
      }

      & > li {
        margin: 0;
        width: 100%;

        span:not(.prev) {
          margin: 0;
          padding: 0;

          &:after {
            display: none;
          }
        }

        &:not(:first-child) {
          span.prev {
            &:before {
              @include icon($icon-left-arrow);
              color: $christine;
              position: absolute;
              left: 0;
              top: 4px;
            }
          }
        }
      }
    }
  }
}