header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 0;
  margin-bottom: 40px;

  @include below(md) {
    padding: 10px 0;
    margin-bottom: 10px;
    border-bottom: solid 1px $seashell;
  }

  & > div {
    flex: 0 0 calc(100% / 3);
    display: flex;

    &:nth-child(2) {
      justify-content: center;
    }

    &:last-child {
      justify-content: flex-end;
    }
  }

  img {
    max-width: 100%;
  }

  .icon-estimation {
    display: flex;
    align-items: center;

    @include below(sm) {
      padding: 10px;
    }

    &:before {
      @include below(sm) {
        margin-right: 0;
      }
    }

    span {
      @include below(sm) {
        display: none;
      }
    }
  }

  .adherent {
    color: $empress;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    position: relative;

    &:before {
      margin-right: 15px;
      font-size: 2rem;
    }

    span {
      @include below(sm) {
        display: none;
      }
    }

    .review {
      position: absolute;
      top: 150%;
      right: 0;

      & > a {
        padding: 8px;
      }
    }
  }
}
