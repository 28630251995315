.popup-overlay {
  align-items: center;
  align-content: center;
}

.popup-desc-content {
  .desc-content {
    max-width: 100%;

    p {
      text-align: left;
    }

    a {
      color: $christine;
    }
  }
}

.popup-content {
  padding: 25px 60px !important;
  width: 40vw !important;
  min-width: 630px;
  border-radius: 10px;
  color: $black;
  cursor: initial;

  @include below(sm) {
    min-width: auto;
    width: 90vw !important;
    padding: 25px !important;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }

  a.btn {
    text-align: center;
  }

  p {
    text-align: center;
    font-weight: 300;
    font-size: 2rem;
    max-width: 100%;

    @include below(sm) {
      font-size: 1.6rem;
    }
  }

  & > .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;

    &:after {
      content: "+";
      font-size: 4rem;
      font-weight: 300;
      transform: rotate(45deg);
      cursor: pointer;
    }

    &:not(:hover):after {
      color: lighten($color-text-dark, 60);
    }
  }

  & > .popup-title {
    align-self: flex-start;
  }
}
