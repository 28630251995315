@mixin icon($icon) {
  display: inline-block;
  font-family: 'irma';
  font-style: normal;
  font-weight: normal;
  content: $icon;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icon-left-arrow: '\0046';
