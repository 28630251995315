.ant-slider {
  flex: 1 0 auto;
  margin: 10px 0;

  @include below(sm) {
    width: 100%;
  }

  &:hover{
    .ant-slider-rail {
      background-color: transparentize($christine, 0.3);
    }
  }

  .ant-slider-dot {
    background-color: $christine;
    border: none;
    width: 7px;
    height: 7px;
    bottom: -3px;
  }

  .ant-slider-handle {
    border: none;
    box-shadow: none;
    background-color: $christine;
  }

  .ant-slider-rail {
    background-color: transparentize($christine, 0.3);
    border-radius: 4px;
    height: 7px;
  }

  .ant-slider-step {
    height: 7px;

    .ant-slider-dot {
      display: none;
    }
  }

  .ant-slider-track {
    display: none;
  }

  .ant-slider-mark-text {
    width: 20px;
    color: black;
    font-weight: 300;
  }
}

.ant-tooltip-inner {
  background-color: $christine;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-align: center;
  padding: 6px 0;
  cursor: pointer;
}

.ant-tooltip-arrow {
  height: 20px;

  .ant-tooltip-arrow-content {
    width: 12px;
    height: 12px;
    background-color: $christine;
  }
}