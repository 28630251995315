h2 {
  font-size: 3.2rem;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 30px;
  line-height: 1.2em;
  display: flex;
  align-items: center;

  @include below(md) {
    color: $christine;
    text-align: center;
  }

  @include below(sm) {
    font-size: 2rem;
  }
}

span.category {
  display: inline-block;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 20px;
}

span.desc {
  color: $christine;
  display: inline-flex;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  border: solid 1px $christine;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  font-size: 1.4rem;
  line-height: 0;
  font-weight: 700;
  margin-left: 15px;
  z-index: 100;
  cursor: pointer;
}

p {
  margin-top: 0;
  margin-bottom: 20px;
}