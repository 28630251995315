div.glass, div.dioptry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @include below(sm) {
    flex-direction: column;
    align-items: flex-start;
  }

  label {
    font-size: 1.6rem;
    flex: 0 0 auto;

    @include below(sm) {
      margin-bottom: 15px;
    }

    &:first-child {
      margin-right: 25px;
      font-weight: 700;
    }
  }
}

div.dioptry {
  margin-top: 60px;

  @include below(sm) {
    margin-top: 20px;
  }

  label {
    &:first-child {
      margin-right: 40px;

      @include below(sm) {
        margin-bottom: 40px;
      }
    }
  }
}
