// Fonts
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,600,700&display=swap');

$primary-font: "Lato", sans-serif;
$secondary-font: "SF Pro Display Regular", sans-serif;

$black: #000;
$christine: #eb690b;
$atomicTangerine: #ff9658;
$lightningYellow: #fdc41d;
$seashell: #f1f1f1;
$white: #fff;
$nobel: #b3b3b3;
$empress: #716F70;
$gold: #ffd700;

$color-text-dark: $black;
$color-text-light: $white;

// Media Queries
$breakpoints: (
        xs: 414px,
        sm: 768px,
        md: 992px,
        lg: 1200px,
        xl: 1600px
);

// Default container width
$lg-container: 1440px;
$md-container: 1180px;

@font-face {
  font-family: 'SF Pro Display Regular';
  src: url('../fonts/SFProDisplay-Regular.eot');
  src: url('../fonts/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/SFProDisplay-Regular.woff') format('woff'),
  url('../fonts/SFProDisplay-Regular.ttf') format('truetype'),
  url('../fonts/SFProDisplay-Regular.svg#irma') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Generated by Glyphter (http://www.glyphter.com) on  Tue May 05 2020*/
@font-face {
  font-family: 'irma';
  src: url('../fonts/irma.eot');
  src: url('../fonts/irma.eot?#iefix') format('embedded-opentype'),
  url('../fonts/irma.woff') format('woff'),
  url('../fonts/irma.ttf') format('truetype'),
  url('../fonts/irma.svg#irma') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before{
  display: inline-block;
  font-family: 'irma';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
.icon-estimation:before{content:'\0041';}
.icon-tooltip:before{content:'\0042';}
.icon-more:before{content:'\0043';}
.icon-question:before{content:'\0044';}
.icon-user:before{content:'\0045';}
.icon-arrow-left:before{content:'\0046';}
