.timeline-tooltip {
  @include below(md) {
    max-width: none;
    min-width: 250px;
  }

  &.rac {
    .ant-tooltip-arrow:before, .ant-tooltip-inner {
      background-color: $christine;
    }
  }

  &.mutuelle {
    .ant-tooltip-arrow:before, .ant-tooltip-inner {
      background-color: $atomicTangerine;
    }
  }

  &.ro {
    .ant-tooltip-arrow:before, .ant-tooltip-inner {
      background-color: $lightningYellow;
    }
  }

  .ant-tooltip-arrow:before {
    box-shadow: none;
  }

  .ant-tooltip-inner {
    width: 195px;
    height: auto;
    font-size: 2rem;
    border-radius: 5px;
    text-align: left;
    padding: 0;

    @include below(md) {
      width: auto;
      font-size: 1.2rem;
    }

    p {
      padding: 15px;
      margin: 0;

      @include below(md) {
        padding: 5px;

        br {
          display: none;
        }
      }

      span.light {
        font-weight: 300;
      }
    }

    span.franchise {
      padding: 5px 15px;
      display: block;
      background-color: transparentize($black, 0.8);

      @include below(md) {
        padding: 5px;
      }
    }
  }
}