label.input {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: 300;
  cursor: pointer;
  margin-right: 20px;

  &:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border: solid 1px $nobel;
    border-radius: 20px;
    margin-right: 10px;
  }

  input {
    position: absolute;
    left: -9999px;

    &:checked ~ span.checked {
      width: 14px;
      height: 14px;
      background-color: $christine;
      border-radius: 15px;
      position: absolute;
      left: 3px;
      top: 5px;
    }
  }
}